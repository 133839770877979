import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import { FaEarthAfrica } from "react-icons/fa6";
import { FaArtstation } from "react-icons/fa";
import { FaDigitalOcean } from "react-icons/fa";
import { FaCreativeCommonsSa } from "react-icons/fa";
import Image1 from "../asset/img-40.jpg"
import Image2 from "../asset/img-39.jpg"
import Image3 from "../asset/img-12.jpg"
import Image4 from "../asset/img-11.jpg"
import Image5 from "../asset/img-14.jpg"
import Image6 from "../asset/img-15.jpg"
import Image7 from "../asset/img-16.jpg"
import Image8 from "../asset/img-17.jpg"



const Wrapper4 = () => {

    return (
        <div className="mt-[5rem] block md:hidden">
            <div className="">


                <div className='mt-32'>
                    <Swiper

                        slidesPerView={1}
                        spaceBetween={30}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="mySwiper "
                    >
                        <SwiperSlide>
                            <img src={Image1} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image2} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image3} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image4} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image5} className='w-[300px] h-[400px] mr-[-5px]' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image6} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image7} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image8} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Image4} className='w-[300px] h-[400px] ml-5' />
                        </SwiperSlide>
                    </Swiper>
                </div>

            </div>
        </div>
    );
};

export default Wrapper4;
