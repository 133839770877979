import React, { useState, useEffect } from 'react';
import Preloader from './Preloader';
// import GraySofa from '../asset/gray-sofa.png';

const Wrapper2 = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 6000);

        return () => clearTimeout(timer);
    }, []);
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div className='modalconent' id='mainmodal'>
                    <div className='mt-20'>
                        <div className='scrolling '>
                            <div className='scroll-left '>
                                <div class="image-3">
                                    <p className='md:text-[100px] text-[40px]'>Decoration Interior Design  Modern Structures Thinking outside of the box</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Wrapper2