import React from 'react'
import { FaRegCopyright } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const OverAllFooter = () => {
    return (
        <div className='bg-black text-white'>
            <div className="max-w-[1140px] mx-auto">
                <div className='flex justify-between py-[110px] md:flex-row flex-col md:ml-0 ml-4 md:gap-0 gap-3'>
                    <a href='https://www.google.com/maps/dir/13.0925053,80.2432832/j+design+interiors/@13.0096106,80.0164239,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a525f231a05bd21:0xd6fcdd44daeba443!2m2!1d80.1482071!2d12.9225113?entry=ttu'>

                        <div className='flex flex-col gap-2'>
                            <h5 className='text-[20px] font-bold '>Our Office Address</h5>
                            <p className='leading-10'> MJL COMPLEX.1,VENUGOPAL STREET,<br /> Velachery Main Rd, near VVV MAHAL,<br /> Mahalakshmi Nagar, Rajakilpakkam,<br /> Chennai, Tamil Nadu 600073</p>
                        </div>
                    </a>
                    <div className='text-white'>
                        <h5 className='text-[20px] font-bold'>Our Services:</h5>
                        <div className=' text-[18px] flex flex-col gap-2'>

                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' />  <p>Modular Kitchen</p></div>
                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' />  <p>Bedroom Interiors</p></div>
                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' />  <p>Wardrobe Interiors</p></div>
                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' />  <p>Pooja Unit Interiors</p></div>
                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' /> <p>Living Interiors</p></div>
                            <div className='flex gap-2 items-center'><IoIosArrowForward className='z-[1]' />  <p>Tv unit Interiors</p></div>
                        </div>
                    </div>
                    <div className='text-[30px] text-white z-[1]'>
                        <h5 className='text-[20px] font-bold'>Follow Us:</h5>
                        <div className='flex mt-5 gap-4'>
                            <a href='https://www.instagram.com/jdesigninteriorschennai?igsh=aHJ1dW10OTNxZmRv'>
                                <FaInstagram />
                            </a>
                            <a href='https://www.facebook.com/jdesigninteriorschennai'>

                                <FaFacebookF />
                            </a>
                            <a href='https://youtube.com/@jdesigninteriors7740?si=lJme1Svv7VK45_YI'>
                                <FaYoutube />
                            </a>
                            <a href='https://www.linkedin.com/company/j-designinteriors/?viewAsMember=true'>
                                <FaLinkedinIn />
                            </a>
                        </div>
                    </div>
                    {/* <div className='flex flex-col gap-5 '>
                        <h5 className='text-[20px] font-bold '>Paris Office </h5>
                        <p className=' text-[17px]'>+33 1 23 45 9966</p>
                        <p className='text-[16px]'>info@renovatioparis.com</p>
                        <p className='text-[14px]'>123 Rue de la Paix, 75002 Paris, France</p>
                    </div>
                    <div className='flex flex-col gap-5 '>
                        <h5 className='text-[20px] font-bold '>Berlin Office </h5>
                        <p className=' text-[17px]'>+49 30 12343693</p>
                        <p className=' text-[16px]'>info@renovatioberlin.com</p>
                        <p className=' text-[14px]'>Mauerstrasse 45, 10117 Berlin, Germany</p>
                    </div> */}
                </div>
            </div>
            <div className='flex mt-10 items-center gap-1 text-[#353535] md:text-[12px] text-[9px] justify-center pb-10  mr-9 md:mr-0'>
                <FaRegCopyright />
                <span className='md:tracking-[8px] tracking-[5px] '>2024 JDESIGN. All Rights Reserved.</span>
            </div>
        </div>
    )
}

export default OverAllFooter;