import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import Preloader from '../Preloader';
import Navbar from '../Navbar2';
import { Link } from "react-router-dom";



import KitchenDesigns1 from "../../asset/kitchen1.jpg";
import WardrobeDesigns from "../../asset/Wardrobe Designs1.jpg";
import LivingRoomDesigns from "../../asset/living-room-interior1.jpg";
import BedRoomDesigns from "../../asset/Bed Room Designs1.jpg";
import DiningRoomDesigns from "../../asset/Dining Room Designs.jpeg";
import BalconyDesigns from "../../asset/Balcony Designs1.webp";
import { IoMdArrowDropright } from "react-icons/io";



import { FaRegCopyright } from "react-icons/fa6";


const Project = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div className='bg-gray-200'>
                    <Navbar />
                    <div>
                        <div className="max-w-[1300px] mx-auto ">
                            <div className='text-center mt-16'><h1 className='md:text-[2rem] text-[2rem]  text-[#073842] font-bold '>Projects <br /><span className='md:ml-40 ml-0 text-[4rem]'>Gallery</span></h1></div>
                            <div className='flex flex-col mt-10'>

                                <div className='flex justify-between md:flex-row flex-col md:ml-0 ml-4 md:gap-0 gap-3 mt-10'>
                                    <Link to="/KitchenDesigns1" >

                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={KitchenDesigns1} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3> Kitchen Designs </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                    <Link to="/WardrobeDesigns1" >
                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={WardrobeDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3>  Wardrobe Designs  </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                    <Link to="/LivingRoomDesigns1" >
                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={LivingRoomDesigns} className='md:w-[400px] h-[270px] w-[20rem] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3>  Living Room Designs  </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                </div>

                                <div className='flex mt-16 justify-between md:flex-row flex-col md:ml-0 ml-4 md:gap-0 gap-3'>
                                    <Link to="/BedRoomDesigns1" >

                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={BedRoomDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3>  Bed Room Designs </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                    <Link to="/DiningRoomDesigns1" >

                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={DiningRoomDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3>  Dining Room Designs  </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                    <Link to="/BalconyDesigns1" >

                                        <div className="rounded-lg overflow-hidden block">
                                            <img src={BalconyDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                                            <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                                <h3>  Balcony Designs  </h3>
                                                <IoMdArrowDropright className='text-[23px]' />
                                            </div>
                                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                                        </div>
                                    </Link>
                                </div>

                            </div>


                        </div>
                        <div className='flex mt-10 items-center gap-1 text-[#353535] md:text-[12px] text-[9px] justify-center pb-10  mr-9 md:mr-0'>
                            <FaRegCopyright />
                            <span className='md:tracking-[8px] tracking-[5px] '>2024 JDESIGN. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>

            )
            }
        </div >
    )
}

export default Project