import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import { Navbar } from "../Navbar";
// import Wrapper7 from "../Wrapper7";
import Wrapper8 from "../Wrapper8";
import OverAllFooter from "../OverAllFooter";
import CardSlider from "../CardSlider";
// import AboutCardMobile from "../Aboutus/AboutusCardMobile";
import CardItem from "../CardItem";
import ImageAbou1 from "../../asset/img-12.jpg";
import ImageAbou2 from "../../asset/img-14.jpg";
import ShowCase1 from "../../asset/img-16.jpg";
import ShowCase2 from "../../asset/img-19.jpg";
import ShowCase3 from "../../asset/img-20.jpg";
import ShowCase4 from "../../asset/img-23.jpg";
import ShowCase5 from "../../asset/img-24.jpg";
import ShowCase6 from "../../asset/img-25.jpg";

import "react-slider-image-js/dist/index.css";


import { ReactSlider } from "react-slider-image-js";
import Sliderimage1 from "../../asset/img-24.jpg"
import Sliderimage2 from "../../asset/img-25.jpg"
import Sliderimage3 from "../../asset/img-26.jpg"
import Sliderimage4 from "../../asset/img-27.jpg"
const styles = {
    margin: 30,
    textAlign: "center",
};

const imagesArr = [
    { src: Sliderimage1 },
    { src: Sliderimage2 },
    { src: Sliderimage3 },
    { src: Sliderimage4 }
];
const AboutUs = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    // const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const nextIndex = (currentIndex + 1) % list.length;
    //         setCurrentIndex(nextIndex);
    //     }, 3000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [currentIndex]);
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div>
                    {/* <AboutCardMobile /> */}
                    <div className="">
                        <div className="about text-white ">
                            <div className="">
                                <Navbar />
                            </div>
                        </div>
                        <div>
                            <div className="max-w-[1140px] mx-auto">
                                <div className="flex justify-center lg:gap-40 gap-5 lg:mt-20 mt-5 md:flex-row flex-col">
                                    <div className="flex flex-col gap-3 ml-[-90px] lg:mt-10 mt-5">
                                        <h1 className="lg:text-[6rem] text-[2rem] font-bold  text-[#073842]  leading-none tracking-tighter ml-28">
                                            About Us
                                        </h1>
                                        <hr className="text-[#073842] mt-[-3px] " />
                                        {/* <span className="text-[30px] text-[#073842] font-medium ">
                                            Where Imagination Meets Interiors
                                        </span> */}
                                    </div>
                                    <div className='lg:h-[500px] lg:w-[500px] lg:m-auto  h-[300px] w-[300px] ml-7 '>
                                        <ReactSlider
                                            images={imagesArr}
                                            imageSrcKey={"src"}
                                            imageAltKey={"src"}
                                            slideAnimationDuration={"1000ms"}
                                            isAutoSlide={true}
                                            autoSlideDuration={3000}
                                        />
                                    </div>
                                </div>
                                <div className='font-adventor md:mt-[5rem] mt-[1rem] ml-4'>
                                    <div className="max-w-[1140px] mx-auto">
                                        {/* <h1 className='md:text-[6rem] text-[2rem] font-bold  text-[#073842] md:w-[40rem] w-[15rem] leading-none tracking-tighter'>About Us</h1> */}
                                        <hr className='mt-8' />
                                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                                            <span>At J Design Interiors, we bring dreams to life through exquisite Residential and commercial interiors. Based in the vibrant city of Chennai, we specialize in creating spaces that reflect individual personalities, lifestyles, and business identities.

                                            </span>

                                        </div>

                                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                                            <span>Founded by the visionary Jaganathan, our team is driven by a passion for design excellence and a commitment to exceeding client expectations. With a keen eye for detail and a focus on functionality, we transform spaces into stunning works of art that enhance everyday living and inspire success in business.

                                            </span>

                                        </div>
                                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                                            <span>Discover the transformative power of exceptional design with J Design Interiors. Let us elevate your space to new heights of beauty and functionality.

                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Wrapper7 /> */}
                        <Wrapper8 />
                        <OverAllFooter />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AboutUs;
