import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./component/HomePage";
import AboutUs from "./component/Aboutus/AboutUs";
import Services from "./component/Services/Services";
import Project from "./component/Project/Project";
import News from "./component/News/News";
import KitchenDesigns1 from "./component/KitchenDesigns1";
import WardrobeDesigns1 from "./component/WardrobeDesigns1";
import LivingRoomDesigns1 from "./component/LivingRoomDesigns1";
import BedRoomDesigns1 from "./component/BedRoomDesigns1";
import DiningRoomDesigns1 from "./component/DiningRoomDesigns1";
import BalconyDesigns1 from "./component/BalconyDesigns1";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Contact from "./component/Contact/Contact"; // index.js or App.js

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/News" element={<News />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/KitchenDesigns1" element={<KitchenDesigns1 />} />
          <Route path="/WardrobeDesigns1" element={<WardrobeDesigns1 />} />
          <Route path="/LivingRoomDesigns1" element={<LivingRoomDesigns1 />} />
          <Route path="/BedRoomDesigns1" element={<BedRoomDesigns1 />} />
          <Route path="/DiningRoomDesigns1" element={<DiningRoomDesigns1 />} />
          <Route path="/BalconyDesigns1" element={<BalconyDesigns1 />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
