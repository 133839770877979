import React, { useState, useEffect } from 'react';
import Preloader from './Preloader';
import { MdOutlineArrowOutward } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
// import GraySofa from '../asset/gray-sofa.png';
import Sliderimage1 from "../asset/img-24.jpg"
import Sliderimage2 from "../asset/img-25.jpg"
import Sliderimage3 from "../asset/img-26.jpg"
import Sliderimage4 from "../asset/img-27.jpg"

import "react-slider-image-js/dist/index.css";
import { ReactSlider } from "react-slider-image-js";
const imagesArr = [
    { src: Sliderimage1 },
    { src: Sliderimage2 },
    { src: Sliderimage3 },
    { src: Sliderimage4 }
];


const Wrapper1 = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    const spanStyles = [
        { '--i': 1 },
        { '--i': 2 },
        { '--i': 3 },
        { '--i': 4 },
        { '--i': 5 },
        { '--i': 6 },
        { '--i': 7 },
    ];

    const letters = ['A', 'b', 'o', 'u', 't', 'U', 's'];
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div className='font-adventor md:mt-[45rem] mt-[30rem] ml-4'>
                    <div className="max-w-[1140px] mx-auto">
                        <div className='flex flex-col lg:flex-row justify-between'>
                            <div className="way text-[8px] lg:text-[20px] ">
                                {letters.map((letter, index) => (
                                    <span key={index} style={spanStyles[index]} >
                                        {letter}
                                    </span>
                                ))}
                                <hr className='mt-3' />
                            </div>
                            <div className='lg:h-[500px] lg:w-[500px] lg:m-auto  h-[300px] w-[300px] ml-7'>
                                <ReactSlider
                                    images={imagesArr}
                                    imageSrcKey={"src"}
                                    imageAltKey={"src"}
                                    slideAnimationDuration={"1000ms"}
                                    isAutoSlide={true}
                                    autoSlideDuration={3000}
                                />
                            </div>
                        </div>
                        {/* <h1 className='md:text-[6rem] text-[2rem] font-bold  text-[#073842] md:w-[40rem] w-[15rem] leading-none tracking-tighter'>About Us</h1> */}
                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                            <span>At J Design Interiors, we bring dreams to life through exquisite Residential and commercial interiors. Based in the vibrant city of Chennai, we specialize in creating spaces that reflect individual personalities, lifestyles, and business identities.

                            </span>

                        </div>

                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                            <span>Founded by the visionary Jaganathan, our team is driven by a passion for design excellence and a commitment to exceeding client expectations. With a keen eye for detail and a focus on functionality, we transform spaces into stunning works of art that enhance everyday living and inspire success in business.

                            </span>

                        </div>
                        <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                            <span>Discover the transformative power of exceptional design with J Design Interiors. Let us elevate your space to new heights of beauty and functionality.

                            </span>

                        </div>
                    </div>
                </div>
            )}
        </div >
    )
}

export default Wrapper1