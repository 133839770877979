import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import Navbar from '../Navbar2';
import ImageNew1 from "../../asset/living-room-interior-wall-mockup-warm-.jpg";
import ImageNew2 from "../../asset/living-room-interior-loft-style.jpg";
import ImageNew3 from "../../asset/minimalist-compositionvc.jpg";
import ImageNew4 from "../../asset/modern-style-conceptual-interio.jpg";
import ImageNew5 from "../../asset/modern-living-room-clean-lines-neutral-colors-natural-elements-design-concept-1.jpg";
import ImageNew6 from "../../asset/home-mockup-living-room-pink-brown-colors-3d-rendering-550x642.jpg";
import { CiCalendar } from "react-icons/ci";
import { GoShareAndroid } from "react-icons/go";
import { FaRegCopyright } from "react-icons/fa6";
import Wrapper7 from '../Wrapper7';




const News = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <Navbar />
                    <div className="max-w-[1140px] mx-auto">
                        <div className='text-center mt-16'><h1 className='md:text-[6rem] text-[2rem] text-[#073842] font-bold '>TESTIMONIALS</h1></div>
                        <Wrapper7 />
                    </div>
                </div>
            )}
        </div>
    )
}

export default News