import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import Navbar from '../Navbar2';
import OverAllFooter from '../OverAllFooter';
import { MdFacebook } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
// import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Contact = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your submission logic here
        console.log(formData); // For testing, you can log the form data
    };
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <Navbar />
                    <div>
                        <div className='contact-img'></div>
                        <div className="max-w-[950px] mx-auto">
                            <div className='flex justify-between mt-10 md:flex-row flex-col'>
                                <div className="flex flex-col gap-3 md:ml-[-90px] ml-4 mt-10">
                                    <h1 className="md:text-[6rem] text-[2rem] font-bold  text-[#073842]  leading-none tracking-tighter">
                                        Let's Talk
                                    </h1>
                                    <hr className="text-[#073842] mt-[-3px] " />
                                    <span className="md:text-[30px] text-[16px] text-[#073842] font-medium ">
                                        Got a project on your mind? Let's discuss<br /> about the details.
                                    </span>
                                    <div className='flex flex-col text-[#203556] font-bold mt-10'>
                                        <span className='md:text-[20px] text-[16px]'>Call Us JAGANATHAN</span>
                                        <span className='md:text-[28px] text-[16px]'>+91 9566557428</span>
                                        <div className='flex text-[#061B506E] mt-7 gap-3 items-center'>

                                            <a href='https://www.instagram.com/jdesigninteriorschennai?igsh=aHJ1dW10OTNxZmRv'>
                                                <FaInstagram className='text-[22px]' />
                                            </a>
                                            <a href='https://www.facebook.com/jdesigninteriorschennai'>

                                                <MdFacebook className='text-[22px]' />
                                            </a>
                                            <a href='https://youtube.com/@jdesigninteriors7740?si=lJme1Svv7VK45_YI'>
                                                <FaYoutube className='text-[22px]' />
                                            </a>
                                            <a href='https://www.linkedin.com/company/j-designinteriors/?viewAsMember=true'>
                                                <FaLinkedinIn className='text-[22px]' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-10">
                                        <div className="flex flex-col gap-4">

                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                className='w-[450px] px-5 py-1 my-1 border-3 border-solid border-red-300 transition duration-500 focus:border-red-500 outline-none '
                                            />
                                            <hr className='w-[20rem] md:w-[29rem] md:ml-0 ml-4' />
                                        </div>
                                        <div className="wpforms-field wpforms-field-email">

                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                className='w-[450px] px-5 py-1 my-3 border-3 border-solid border-red-300 transition duration-500 focus:border-red-500 outline-none'

                                            />
                                            <hr className='w-[20rem] md:w-[29rem] md:ml-0 ml-4' />
                                        </div>
                                        <div className="wpforms-field wpforms-field-email">

                                            <input
                                                type="contact"
                                                id="contact"
                                                name="contact"
                                                placeholder="Contact"
                                                value={formData.contact}
                                                onChange={handleChange}
                                                required
                                                className='w-[450px] px-5 py-1 my-3 border-3 border-solid border-red-300 transition duration-500 focus:border-red-500 outline-none'

                                            />
                                            <hr className='w-[20rem] md:w-[29rem] md:ml-0 ml-4' />
                                        </div>
                                        <div className="wpforms-field wpforms-field-textarea">

                                            <textarea
                                                id="message"
                                                name="message"
                                                placeholder="Your Message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                className='w-[450px] px-5 py-4 pb-40  my-1 border-3 border-solid border-red-300 transition duration-500 focus:border-red-500 outline-none'

                                            />   <hr className='w-[20rem] md:w-[29rem] md:ml-0 ml-4' />
                                        </div>
                                    </div>
                                    <div className="text-[16px] leading-40 bg-[#203556] rounded-3xl w-[100px] text-white text-center mt-8 py-2 px-1 mb-40 ml-5">
                                        <button type="submit" className="">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <OverAllFooter />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contact