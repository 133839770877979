import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import { IoCloseSharp } from "react-icons/io5";
// import Preloader from "./Preloader"
import Logo from "../asset/PNG .png"


export const Navbar = () => {

    // const [loading, setLoading] = useState(true);

    // useEffect(() => {

    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);

    //     return () => clearTimeout(timer);
    // }, []);
    const [menu, setmenu] = useState(false);
    const toggleSpacePlanning = () => {
        setmenu(!menu);
        if (!menu) {
            setmenu("Renovatio Interiors provides expert space planning services to optimize the functionality and flow of a given space. They analyze the available area and create layouts that maximize efficiency and create a harmonious environment.");
        } else {
            setmenu('');
        }
    };

    return (


        <div className=''>
            {/* {loading ? (
                <Preloader />
            ) : ( */}
            <nav className="mt-[-20px]">
                <div className="max-w-[1140px] mx-auto">
                    <div className=" flex   justify-between items-center transition-color duration-400
transition-background-color duration-400
transition-opacity duration-400 ">
                        <IoMenu className="block sm:hidden md:hidden text-[25px] mt-8 ml-3 text-white" onClick={toggleSpacePlanning} />
                        {menu && <div>
                            <div className="bg-white text-black fixed w-[490px] z-50 ml-[-125px] md:mt-[-32px] mt-[-15px]  h-[700px] block md:hidden ">
                                <div>
                                    <IoCloseSharp onClick={toggleSpacePlanning} className='ml-[6rem] mt-[1rem] text-[25px]' />
                                </div>
                                <div>
                                    <ul >
                                        <li className="text-center ">
                                            <Link to="/home" className='text-black '>Home</Link></li>
                                        <hr />
                                        <li className=" text-center p-3">
                                            <Link to="/AboutUs" className='text-black'>AboutUs</Link>
                                            <hr />
                                        </li>

                                        <li className=" text-center p-3">
                                            <Link to="/services" className='text-black '>Services</Link>
                                            <hr />
                                        </li>
                                        <li className=" text-center p-3">
                                            <Link to="/project" className='text-black'>Project</Link>
                                            <hr />
                                        </li>
                                        <li className=" text-center p-3">
                                            <Link to="/news" className='text-black'>Testimonials</Link>
                                            <hr />
                                        </li>
                                        <li className="text-center">
                                            <Link to="/contact" className='text-black'>Contact</Link>
                                            <hr />
                                        </li>

                                    </ul>
                                </div>
                            </div><div className="text-black bg-opacity-60 fixed inset-0 z-10" onClick={toggleSpacePlanning}></div>

                        </div>}
                        <div className="text-white md:text-[30px] text-[22px] mr-[260px] md:mr-0 font-extrabold   md:mt-0 mt-8 ">
                            <img src={Logo} className='lg:w-[120px] lg:mt-10 w-[80px]' />

                        </div>

                        <ul className='navbar md:flex items-center  text-[17px] font-medium hidden '>
                            <li className=''>
                                <Link to="/home" className='active flex items-center text-white'>Home</Link>

                            </li>
                            <li className='nav '>
                                <Link to="/AboutUs" className='flex items-center text-white'>About Us</Link>

                            </li>
                            <li className='nav'>
                                <Link to="/services" className='flex items-center text-white'>Services</Link>

                            </li>
                            <li className='nav'>
                                <Link to="/project" className='flex items-center text-white'>Project</Link>

                            </li>
                            <li className='nav'>
                                <Link to="/news" className='flex items-center text-white'>Testimonials</Link>

                            </li>
                            <li className='nav'>
                                <Link to="/contact" className='flex items-center text-white'>Contact</Link>

                            </li>

                            <IoSearch className="text-white text-[20px]" />
                        </ul>

                    </div>
                </div>
            </nav>
            {/* )} */}
        </div>
    )
}

