import React from "react";
import Slider from "react-slick";
import { MdOutlineArrowOutward } from "react-icons/md";
import image1 from "../asset/t-01.jpg";
import image2 from "../asset/t-02.jpg";
import image3 from "../asset/t-03.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Brand1 from "../asset/brand1.png"
import Brand2 from "../asset/brand2.png"
import Brand3 from "../asset/brand3.png"
import Brand4 from "../asset/brand4.png"
import Brand5 from "../asset/brand5.png"
import Brand6 from "../asset/brand6.png"

import Profile from "../asset/profile.png"


const Wrapper7 = () => {


    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    return (
        <div>
            <div className="max-w-[1140px] mx-auto">
                {/* <div className='mt-20 md:ml-0 ml-4'>
                    <div>
                        <div className='flex items-center  justify-end text-[24px] text-[#073842] font-medium '>Latest News
                            <MdOutlineArrowOutward />
                            <hr className='text-[#073842]' /></div>
                        <h1 className='md:text-[6rem] text-[2rem] font-bold  text-[#073842] md:w-[55rem] w-[20rem] leading-none tracking-tighter '>Meet the Masters of Interior Design</h1>
                    </div>
                    <hr className='mt-10' />
                    <div className='mt-4'>
                        <span className='text-[32px] text-[#073842] font-medium '>
                            One Team, Infinite Possibilities
                        </span>
                    </div>
                    <div className='flex md:flex-row flex-col mt-20 justify-between'>
                        <img src={image1} className='w-[320px] mt-12' />
                        <img src={image3} className='w-[320px] mb-[30px] mt-10 md:mt-0' />
                        <img src={image2} className='w-[320px] md:mt-12 mt-10' />
                    </div>
                </div> */}
                {/* <div className="mt-32">
                    <Slider {...settings}>
                        {data.map((d) => (
                            <div key={d.name} className="bg-white lg:h-[450px] h-[300px] text-black rounded-xl flex flex-col lg:flex-row">
                                <div className='h-56 bg-orange-500 flex justify-center items-center rounded-t-xl'>
                                    <img src={d.img} alt="" className="lg:h-44 lg:w-44 rounded-full h-20 w-20" />
                                </div>

                                <div className="flex flex-col items-center justify-center gap-4 p-4">
                                    <p className="lg:text-xl text-[15px] font-semibold">{d.name}</p>
                                    <p className="text-center">{d.review}</p>
                                    <button className='bg-orange-500 text-white text-lg px-6 py-1 rounded-xl'>Read More</button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div> */}
                <div className="lg:block hidden">
                    <Slider {...settings} className="mt-10 flex flex-col lg:flex-row">

                        <div className="flex flex-col items-center">
                            <img src={Profile} className='w-[250px]' />
                            <div className="flex flex-col items-center justify-center gap-4 p-4">
                                <p className="lg:text-xl text-[15px] font-semibold text-center">Malini Malu</p>
                                <p className="text-center">`Jagan is really amazing. I just found him through insta and just checked with him regarding my house renovation. He was very professional to be honest.`
                                </p>
                                <button className='bg-orange-500 text-white lg:text-lg text-[10px] lg:px-6 lg:py-1 rounded-xl p-2'>Read More</button>
                            </div>

                        </div>
                        <div>
                            <img src={Profile} className='w-[250px]' />
                            <div className="flex flex-col items-center justify-center gap-4 p-4">
                                <p className="lg:text-xl text-[15px] font-semibold  text-center">Lakshmi narayanan</p>
                                <p className="text-center">`Thank you so much for the quality & commitment of work that you and your team have delivered. We are really glad that we have chosen your interior. `</p>
                                <button className='bg-orange-500 text-white lg:text-lg text-[10px] lg:px-6 lg:py-1 rounded-xl p-2'>Read More</button>
                            </div>

                        </div>
                        <div>
                            <img src={Profile} className='w-[250px]' />
                            <div className="flex flex-col items-center justify-center gap-4 p-4">
                                <p className="lg:text-xl text-[15px] font-semibold  text-center">kiruthi Srini</p>
                                <p className="text-center">`Highly satisfied with the interior and painting work done for our home by Jagan from J Design Interiors. He is very passionate and have good knowledge in designs and works. `</p>
                                <button className='bg-orange-500 text-white lg:text-lg text-[10px] lg:px-6 lg:py-1 rounded-xl p-2'>Read More</button>
                            </div>

                        </div>
                        <div>
                            <img src={Profile} className='w-[250px]' />
                            <div className="flex flex-col items-center justify-center gap-4 p-4">
                                <p className="lg:text-xl text-[15px] font-semibold  text-center">Rocky</p>
                                <p className="text-center">Initially was hesitant to give him project thinking he is too young but I was wrong , the energy level he has in completing the work within timelines . '</p>
                                <button className='bg-orange-500 text-white lg:text-lg text-[10px] lg:px-6 lg:py-1 rounded-xl p-2'>Read More</button>
                            </div>

                        </div>
                        <div>
                            <img src={Profile} className='w-[250px]' />
                            <div className="flex flex-col items-center justify-center gap-4 p-4">
                                <p className="lg:text-xl text-[15px] font-semibold  text-center">Saroja Jayaraman</p>
                                <p className="text-center"> `Extremely professional, and provides quality work for the money. They offer variety of custom options in interiors catering to the customer needs. `</p>
                                <button className='bg-orange-500 text-white lg:text-lg text-[10px] lg:px-6 lg:py-1 rounded-xl p-2'>Read More</button>
                            </div>

                        </div>

                    </Slider>
                </div>
                <div className="mt-20">
                    <h3 className="lg:text-[40px] text-[30px] font-bold text-[#073842] ml-5 lg:ml-0">Our Brands</h3>
                    <div className="slider-container ">
                        <Slider {...settings} className="mt-10 ">
                            <div>
                                <img src={Brand1} className='w-[250px]' />

                            </div>
                            <div>
                                <img src={Brand2} className='w-[250px]' />

                            </div>
                            <div>
                                <img src={Brand3} className='w-[250px]' />

                            </div>
                            <div>
                                <img src={Brand4} className='w-[250px]' />

                            </div>
                            <div>
                                <img src={Brand5} className='w-[250px]' />

                            </div>
                            <div>
                                <img src={Brand6} className='w-[250px]' />

                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

const data = [
    {
        name: `Malini Malu`,
        img: `../asset/profile.png`,
        review: `Jagan is really amazing. I just found him through insta and just checked with him regarding my house renovation. He was very professional to be honest.`
    },
    {
        name: `Lakshmi narayanan`,
        img: `../asset/profile.png`,
        review: `Thank you so much for the quality & commitment of work that you and your team have delivered. We are really glad that we have chosen your interior. `
    },
    {
        name: `kiruthi Srini`,
        img: `../asset/profile.png`,
        review: `Highly satisfied with the interior and painting work done for our home by Jagan from J Design Interiors. He is very passionate and have good knowledge in designs and works. `
    },
    {
        name: `Rocky`,
        img: `../asset/profile.png`,
        review: `Initially was hesitant to give him project thinking he is too young but I was wrong , the energy level he has in completing the work within timelines . '
`
    },
    {
        name: `Saroja Jayaraman`,
        img: `../asset/profile.png`,
        review: `Extremely professional, and provides quality work for the money. They offer variety of custom options in interiors catering to the customer needs. `
    },

];

export default Wrapper7