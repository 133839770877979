import React from 'react';
import { MdOutlineArrowOutward } from "react-icons/md";
import Typewriter from "./Typewriter";
import { Link } from "react-router-dom";


const Wrapper8 = () => {



    return (
        <div>
            <div className="max-w-[1140px] mx-auto">
                <div className='mt-20 md:ml-0 ml-4'>
                    <div>
                        <div className='flex items-center  md:text-[24px] text-[18px] justify-end  text-[#073842] font-medium md:mr-0 mr-[4rem]'>Contact Us
                            <MdOutlineArrowOutward />
                            <hr className='text-[#073842]' /></div>
                        <h1 className='md:text-[6rem] text-[2rem] font-bold  text-[#073842] w-[55rem] leading-none tracking-tighter'>Get in Touch</h1>
                    </div>
                    <hr className='mt-10' />
                    <div className='mt-4'>
                        <p className='md:text-[32px] text-[17px] text-[#073842] font-medium md:w-[46rem]  w-[20rem]'>
                            Building Relationships, One Conversation at a Time</p>
                    </div>
                </div>
            </div>
            <div className='bg-color mt-20'>
                <div className='image-4 inset-0 z-50'>

                    <h1 className='text-[72px] text-[#FFFFFF] font-bold text-center '>Have a project in mind?</h1>
                    <div>
                        <h1 className='text-[26px] text-[#FFFFFF] font-bold text-center'>
                            Do not hesitate to say <Typewriter text="hello" delay={100} infinite />
                        </h1>
                    </div>
                    <div >
                        <Link to="/contact" className=''>

                            <div className='button flex justify-center mt-5'>
                                <div className='flex items-center gap-5 text-[#FFFFFF] text-[20px] bg-transparent border-[3px] border-inherit justify-center   rounded-2xl  p-3 font-semibold w-[165px] hover:bg-[#FFFFFF]  hover:text-black'><span className='font-bold '>Let's Talk</span><MdOutlineArrowOutward className='[w-10px]' /></div>
                            </div>
                        </Link>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default Wrapper8