import React, { useState, useEffect } from 'react';
import Preloader from './Preloader';
import { MdOutlineArrowOutward } from "react-icons/md";
// import { render } from "react-dom";
import CardSlider from "./CardSlider";
import CardItem from "./CardItem";
// import WrapperMobile from "./Wrapper3Mobile";
import { ReactSlider } from "react-slider-image-js";
import Sliderimage1 from "../asset/img-24.jpg"
import Sliderimage2 from "../asset/img-25.jpg"
import Sliderimage3 from "../asset/img-26.jpg"
import Sliderimage4 from "../asset/img-27.jpg"
import Services from "../asset/img-11.jpg"

import "react-slider-image-js/dist/index.css";
// import image1 from "../asset/modern-interior.png"


const styles = {
    margin: 30,
    textAlign: "center",
};

const imagesArr = [
    { src: Sliderimage1 },
    { src: Sliderimage2 },
    { src: Sliderimage3 },
    { src: Sliderimage4 }
];
const Wrapper3 = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    // const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const nextIndex = (currentIndex + 1) % list.length;
    //         setCurrentIndex(nextIndex);
    //     }, 3000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [currentIndex]);
    const spanStyles = [
        { '--i': 1 },
        { '--i': 2 },
        { '--i': 3 },
        { '--i': 4 },
        { '--i': 5 },
        { '--i': 6 },
        { '--i': 7 },
        { '--i': 8 },
        { '--i': 9 },
        { '--i': 10 },
        { '--i': 11 },
        { '--i': 13 },

    ];

    const letters = ['O', 'u', 'r', '', '', 'S', 'e', 'r', 'v', 'i', 'c', 'e', 's'];
    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div className="">
                    {/* <WrapperMobile /> */}
                    <div className='mt-20  ml-4 '>
                        <div className="max-w-[1140px] mx-auto ">
                            <div className='flex gap-20 lg:flex-row flex-col'>
                                <div>
                                    <div className="way text-[8px] lg:text-[20px]">
                                        {letters.map((letter, index) => (
                                            <span key={index} style={spanStyles[index]}>
                                                {letter}
                                            </span>
                                        ))}
                                    </div>
                                    {/* <h1 className='md:text-[6rem] text-[2rem]  font-bold  text-[#073842] w-[50rem] leading-none tracking-tighter'>Our Services</h1> */}
                                </div>
                                <div>
                                    <img src={Services} alt="" className='lg:w-[500px] w-[350px]' />

                                </div>
                            </div>
                            <hr className='mt-10' />
                            <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                                <span><span className='font-bold text-[25px]'>Home Interiors:</span> From cozy bedrooms to functional kitchens, we specialize in designing interiors that elevate your living experience. Whether you prefer modern minimalism or timeless elegance, our team tailors every detail to suit your taste and lifestyle.

                                </span>

                            </div>
                            <div className='flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                                <span><span className='font-bold text-[25px]'>Commercial Interiors:</span> Your workplace should inspire productivity and creativity. Our commercial interior design services are crafted to enhance functionality and reflect your brand identity. From office spaces to retail outlets, we create environments that foster success.

                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Wrapper3