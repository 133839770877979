import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import Navbar from '../Navbar2';
import Slider from "../SliderServices";
// import Wrapper7 from '../Wrapper7';
import Wrapper8 from '../Wrapper8';
import OverAllFooter from '../OverAllFooter';
// import { FaPlus } from "react-icons/fa6";
import Service from "../../asset/img-16.jpg";
import ImageSer2 from "../../asset/img-22.jpg";
// import { FaPlus } from "react-icons/fa";
import { FaPlus, FaMinus } from "react-icons/fa";

// import Simg1 from "../../asset/simg1.png"
// import Simg2 from "../../asset/simg2.jpg"
// import Simg3 from "../../asset/simg3.jpg"
// import Simg4 from "../../asset/simg4.jpeg"
import Simg5 from "../../asset/col-bgimage-1.jpg"
import { FaStar } from "react-icons/fa6";
// import { MdBed } from "react-icons/md";
// import { SiApachecouchdb } from "react-icons/si";
// import { GiSteelDoor } from "react-icons/gi";

import Kitchen from "../../asset/kitchen (3).png"
import Bedroom from "../../asset/bedroom.png"
import Door from "../../asset/door (1).png"
import Living from "../../asset/living-room.png"
import Foyer from "../../asset/curtain (1).png"
import Celing from "../../asset/dining-table.png"
import Renovation from "../../asset/people (1).png"



const Services = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    // image1-clicking
    const [spacePlanningContent, setSpacePlanningContent] = useState('');
    const [spacePlanningVisible, setSpacePlanningVisible] = useState(false);

    const [consultationContent, setConsultationContent] = useState('');
    const [consultationVisible, setConsultationVisible] = useState(false);

    const [conceptDevelopmentContent, setConceptDevelopmentContent] = useState('');
    const [conceptDevelopmentVisible, setConceptDevelopmentVisible] = useState(false);

    const toggleSpacePlanning = () => {
        setSpacePlanningVisible(!spacePlanningVisible);
        if (!spacePlanningVisible) {
            setSpacePlanningContent("Renovatio Interiors provides expert space planning services to optimize the functionality and flow of a given space. They analyze the available area and create layouts that maximize efficiency and create a harmonious environment.");
        } else {
            setSpacePlanningContent('');
        }
    };

    const toggleConsultation = () => {
        setConsultationVisible(!consultationVisible);
        if (!consultationVisible) {
            setConsultationContent("Content for Interior Design Consultation");
        } else {
            setConsultationContent('');
        }
    };

    const toggleConceptDevelopment = () => {
        setConceptDevelopmentVisible(!conceptDevelopmentVisible);
        if (!conceptDevelopmentVisible) {
            setConceptDevelopmentContent("Content for Concept Development and Visualization");
        } else {
            setConceptDevelopmentContent('');
        }
    };


    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <Navbar />
                    <div className='md:w-full w-full '>
                        <Slider auto speed={3000} >
                            <div className="img-1">

                            </div>
                            <div className="img-2 ">

                            </div>
                            <div className="img-3 ">

                            </div>
                            <div className="img-4">

                            </div>
                        </Slider>
                    </div>
                    <div className="max-w-[1140px] mx-auto">
                        <div className='flex justify-between mt-20'>
                            <div className='text-[#073842] flex md:flex-row flex-col md:mt-20 justify-between ml-4 md:ml-0'>
                                <div><h1 className='md:text-[6rem] text-[2rem] font-bold w-[400px] leading-[100px] tracking-tighter'>Our Services<hr className='md:mt-10 mt-1 ' /></h1></div>
                            </div>
                            <div>
                                <img src={Service} alt="" className='w-[600px]' />

                            </div>
                        </div>
                        {/* <div className='mt-5 ml-4 md:ml-0'>
                            <span className='md:text-[28px] text-[20px] text-[#073842] font-medium  md:w-[30rem] w-[22rem]'>Construction Services that Go Beyond Expectations</span>
                        </div> */}
                        {/* <hr className='mt-5' /> */}
                        <div className='ml-5 lg:ml-0 lg:mt-20 mt-10 flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem]  text-[#073842] font-medium'>
                            <span><span className='font-bold text-[25px]'>Home Interiors:</span> From cozy bedrooms to functional kitchens, we specialize in designing interiors that elevate your living experience. Whether you prefer modern minimalism or timeless elegance, our team tailors every detail to suit your taste and lifestyle.

                            </span>

                        </div>
                        <div className='ml-5 lg:ml-0  flex md:flex-row flex-col items-center justify-between md:text-[20px] text-[16px] md:w-[70rem] w-[19rem] mt-5 text-[#073842] font-medium'>
                            <span><span className='font-bold text-[25px]'>Commercial Interiors:</span> Your workplace should inspire productivity and creativity. Our commercial interior design services are crafted to enhance functionality and reflect your brand identity. From office spaces to retail outlets, we create environments that foster success.

                            </span>

                        </div>
                        <div className='max-w-[1200px] mx-auto mt-10 lg:ml-0 ml-8' >
                            <h2 className='text-[17px] font-semibold text-[orange]'>SERVICES WE DO</h2>
                            <h1 className='text-[40px] font-semibold  mt-3'>Our Best Services Interior</h1>

                            <div className='ourbest mt-10 '>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white   '>

                                    {/* <CountertopsIcon /> */}
                                    <img src={Kitchen} alt="" className='w-[25%] ' />

                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>Modular Kitchen</h3>
                                    <span className='w-[250px] text-gray-600'>Highly efficient & organized, making it easy to use all appliances and storage.</span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    <img src={Bedroom} alt="" className='w-[25%]' />

                                    {/* <MdBed className='text-[50px] text-[orange]' /> */}
                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        Bedroom Design
                                    </h3>
                                    <span className='w-[240px] text-gray-600'>A space that is comfortable, relaxing, & reflective of your personal style.</span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    {/* <GiSteelDoor className='text-[50px] text-[orange]' /> */}
                                    {/* <GiSteelDoor className='text-[3rem] ml-[-8px] text-[orange]' /> */}
                                    <img src={Door} alt="" className='w-[25%]' />

                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        Pooja Unit
                                    </h3>
                                    <span className='w-[220px] text-gray-600'>Should have adequate storage for puja items, books, and other accessories. </span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    {/* <i className="fas fa-living-room"></i> */}
                                    <img src={Living} alt="" className='w-[25%]' />
                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        Living Design
                                    </h3>
                                    <span className='w-[220px] text-gray-600'>A place for relaxation, socializing, comfortable & inviting atmosphere for family.</span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    <img src={Foyer} alt="" className='w-[25%]' />
                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        Foyer Design
                                    </h3>
                                    <span className=' w-[220px] text-gray-600'>A place to make a statement and create a welcoming atmosphere. </span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    <img src={Celing} alt="" className='w-[25%]' />

                                    {/* <SiApachecouchdb className='text-[50px] text-[orange]' /> */}
                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        False Celing
                                    </h3>
                                    <span className='w-[220px] text-gray-600'>An excellent opportunity to add lighting to a stylish room and functional touch.</span>
                                </div>
                                <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                    <img src={Renovation} alt="" className='w-[25%]' />

                                    <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                        Renovation
                                    </h3>
                                    <span className='w-[230px] text-gray-600'>We are master of renovation & innovation of existing any kind of rooms </span>
                                </div>
                            </div>
                            <div className='flex flex-col lg:flex-row'>
                                <div className='flex gap-5 lg:mt-40 mt-10 flex-col lg:flex-row'>
                                    <div className='flex gap-1'>

                                        <FaStar className='text-orange-400' />
                                        <FaStar className='text-orange-400' />
                                        <FaStar className='text-orange-400' />
                                        <FaStar className='text-orange-400' />
                                        <FaStar className='text-orange-400' />
                                    </div>
                                    <p className='text-gray-600 lg:w-[430px] w-[350px] text-[15px] tracking-wider'><span className='text-orange-500 underline font-semibold'>90% Customer Satisfaction</span>  based on 150+ reviews and 20,000 Objective Resource</p>
                                </div>
                                <div className='flex flex-col lg:flex-none mt-60 lg:mt-0'>
                                    <img src={Simg5} alt="" className='lg:w-[37rem] w-[20rem] lg:h-[450px] z-[-1] mt-[-230px] lg:ml-[4rem]' />
                                    <div className='bg-orange-500 text-white lg:mt-[-80px] mt-[-70px] lg:ml-[7rem] ml-[2rem] lg:w-[500px] w-[300px] rounded-3xl p-2'>
                                        <span className='lg:text-[17px] text-[10px] font-semibold ml-16'>OUR Featured Services Interior Design</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Wrapper7 /> */}
                    <Wrapper8 />
                    <OverAllFooter />
                </div>
            )}
        </div>
    )
}

export default Services
