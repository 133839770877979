import React from 'react'
import Navbar from './Navbar2';



import KitchenDesigns from "../asset/Wardrobe Designs1.jpg";
import WardrobeDesigns from "../asset/Wardrobe Designs2.jpg";
import LivingRoomDesigns from "../asset/Wardrobe Designs3.jpg";
import BedRoomDesigns from "../asset/Wardrobe Designs4.jpg";
import DiningRoomDesigns from "../asset/Wardrobe Designs5.jpg";
import BalconyDesigns from "../asset/Wardrobe Designs6.jpg";
import { IoMdArrowDropright } from "react-icons/io";

import { FaRegCopyright } from "react-icons/fa6";

const WardrobeDesigns1 = () => {
    return (
        <div>
            <Navbar />
            <div className="max-w-[1300px] mx-auto ">
                <div className='text-center mt-16'><h1 className='md:text-[2rem] text-[2rem]  text-[#073842] font-bold '>Our Modular Wardrobe Designs</h1></div>
                <div className='flex flex-col mt-10'>

                    <div className='flex justify-between md:flex-row flex-col md:ml-0 ml-4 md:gap-0 gap-3 mt-10'>


                        <div className="rounded-lg overflow-hidden block">
                            <img src={KitchenDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3> Kitchen Designs </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>

                        <div className="rounded-lg overflow-hidden block">
                            <img src={WardrobeDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3>  Wardrobe Designs  </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>
                        <div className="rounded-lg overflow-hidden block">
                            <img src={LivingRoomDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3>  Living Room Designs  </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>
                    </div>
                    <div className='flex mt-16 justify-between md:flex-row flex-col md:ml-0 ml-4 md:gap-0 gap-3'>
                        <div className="rounded-lg overflow-hidden block">
                            <img src={BedRoomDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3>  Bed Room Designs </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>
                        <div className="rounded-lg overflow-hidden block">
                            <img src={DiningRoomDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3>  Dining Room Designs  </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>
                        <div className="rounded-lg overflow-hidden block">
                            <img src={BalconyDesigns} className='md:w-[400px] w-[20rem] h-[270px] hover:scale-125 ease-in duration-150' />
                            {/* <div className='flex items-center bg-white z-[1] mt-[-50px] absolute py-2 px-4 ml-5'>
                                <h3>  Balcony Designs  </h3>
                                <IoMdArrowDropright className='text-[23px]' />
                            </div> */}
                            {/* <div className='ml-7 mt-7'>
                                            <h6 className='text-[22px] font-bold text-[#073842]'>Modern Minimalism</h6>
                                            <span className='text-[#777676] text-[12px] font-bold'>HIGH LINE</span>
                                        </div> */}
                        </div>
                    </div>

                </div>


            </div>
            <div className='flex mt-10 items-center gap-1 text-[#353535] md:text-[12px] text-[9px] justify-center pb-10  mr-9 md:mr-0'>
                <FaRegCopyright />
                <span className='md:tracking-[8px] tracking-[5px] '>2024 JDESIGN. All Rights Reserved.</span>
            </div>
        </div>
    )
}

export default WardrobeDesigns1