import React, { useState, useEffect } from 'react';
// import Preloader from './Preloader';
import { Navbar } from "./Navbar";

import { Wrapper } from "./Wrapper";
import Wrapper1 from './Wrapper1';
import Wrapper2 from './wrapper2';
import Wrapper3 from './Wrapper3';
import Wrapper4 from './Wrapper4';
import Wrapper5 from './Wrapper5';
// import Wrapper6 from './Wrapper6';
import Wrapper7 from './Wrapper7';
import Wrapper8 from './Wrapper8';
import Footer from './Footer';

// import SliderImage from './SliderImage';


const HomePage = () => {
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     // Simulate a delay to show the preloader
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);

    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div>
            {/* {loading ? (
                <Preloader />
            ) : ( */}
            <div>
                <a
                    href="https://wa.me/9566557428"
                    class="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="fa-brands fa-whatsapp mt-4"></i>
                </a>

                <div className='relative'>
                    <Navbar />
                    <Wrapper />
                </div>
                <Wrapper1 />
                <Wrapper2 />
                <Wrapper3 />
                <Wrapper4 />
                {/* <SliderImage /> */}
                <Wrapper5 />
                {/* <Wrapper6 /> */}
                <Wrapper7 />
                <Wrapper8 />
                <Footer />
            </div>

        </div>
    )
}

export default HomePage;