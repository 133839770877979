import React, { useState, useEffect } from 'react';
import Preloader from './Preloader';
import Slider from "./Slider";
import { Link } from "react-router-dom";
// import GraySofa from '../asset/gray-sofa.png';
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdOutlineArrowOutward } from "react-icons/md";


export const Wrapper = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay to show the preloader
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {loading ? (
                <Preloader />
            ) : (
                <div className='w-full absolute md:mt-[-150px] mt-[-10rem] -z-50'>
                    <Slider height="750px" auto speed={6000} className="slider">
                        <div className="image-1">
                            <div className="">
                                {/* <img src={GraySofa} alt="Logo" className='h-[47px]' /> */}
                                {/* <div className="slide-let bg-[#ff6f00] mt-[-300px] ml-[-21rem] text-white w-[2px] h-[407px]  opacity-100 transform translate-x-0 translate-y-0 visible">

                                </div> */}
                                <h3 className='text-white md:text-[27px] md:mt-[-230px] mt-[300px] text-start  md:ml-[-300px] ml-[1rem]'>Welcome to JDESIGN Interiors
                                </h3>
                                <h1 className='md:text-[60px] text-[30px] md:mt-[-20px] mt-0 md:ml-[-300px] ml-[1rem] font-bold text-white text-start'>Creating <br />Harmony Through <br />Design</h1>
                                <div className=''>
                                    <Link to="/contact" className='cursor-pointer '>
                                        <div className='relative z-[1]   bg-[#ff6f00] flex items-center gap-4 lg:w-[195px] w-[120px] lg:px-4 lg:py-3  p-2 rounded-3xl text-white font-bold lg:text-[20px] text-[12px] lg:ml-[-300px] ml-5' >
                                            <button>CONTACT US</button>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="image-2 ">
                            <div className="">
                                {/* <div className="slide-let bg-[#ff6f00] mt-[-300px] ml-[40rem] text-white w-[5px] h-[407px]  opacity-100 transform translate-x-0 translate-y-0 visible">

                                </div> */}
                                <h3 className='text-white md:text-[27px] md:mt-[-230px]  mt-[300px]  text-start  md:ml-[-300px] ml-[1rem]'>Welcome to JDESIGN Interiors
                                </h3>
                                <h1 className='md:text-[60px] text-[30px] md:mt-[-20px] mt-0 md:ml-[-300px] ml-[1rem] font-bold text-white text-start'>Your Space, Your<br /> Signature Style</h1>

                                <div>
                                    <Link to="/contact" className='cursor-pointer '>
                                        <div className='relative z-[1]    bg-[#ff6f00] flex items-center gap-4 lg:w-[195px] w-[120px] lg:px-4 lg:py-3  p-2 rounded-3xl text-white font-bold lg:text-[20px] text-[12px] lg:ml-[-300px] ml-5' >
                                            <button>CONTACT US</button>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="image-7 ">
                            <div className="">
                                {/* <div className="slide-let bg-[#ff6f00] mt-[-300px] ml-[-21rem] text-white w-[5px] h-[407px] opacity-100 transform translate-x-0 translate-y-0 visible">

                                </div> */}
                                <h3 className='text-white md:text-[27px] md:mt-[-230px]  mt-[300px]  text-start  md:ml-[-300px] ml-[1rem]'>Welcome to JDESIGN Interiors
                                </h3>
                                <h1 className='md:text-[60px] text-[30px] md:mt-[-20px]  mt-0 md:ml-[-300px] ml-[1rem] font-bold text-white text-start'>Where Style<br /> Meets Comfort</h1>
                                <div>
                                    <Link to="/contact" className='cursor-pointer '>
                                        <div className='relative z-[1]    bg-[#ff6f00] flex items-center gap-4 lg:w-[195px] w-[120px] lg:px-4 lg:py-3  p-2 rounded-3xl text-white font-bold lg:text-[20px] text-[12px] lg:ml-[-300px] ml-5' >
                                            <button>CONTACT US</button>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="image-8 ">
                            <div className="">
                                {/* <div className="slide-let bg-[#ff6f00] mt-[-300px] ml-[40rem] text-white w-[5px] h-[407px]  opacity-100 transform translate-x-0 translate-y-0 visible">

                                </div> */}
                                <h3 className='text-white md:text-[27px] md:mt-[-230px]  mt-[300px]  text-start  md:ml-[-300px] ml-[1rem]'>Welcome to JDESIGN Interiors
                                </h3>
                                <h1 className='md:text-[60px] text-[30px] md:mt-[-20px]  mt-0 md:ml-[-300px] ml-[1rem] font-bold text-white text-start'>Discover the Art <br />of Living</h1>
                                <div>
                                    <Link to="/contact" className='cursor-pointer '>
                                        <div className='relative z-[1]    bg-[#ff6f00] flex items-center gap-4 lg:w-[195px] w-[120px] lg:px-4 lg:py-3  p-2 rounded-3xl text-white font-bold lg:text-[20px] text-[12px] lg:ml-[-300px] ml-5' >
                                            <button>CONTACT US</button>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </Link>
                                </div>

                                {/* <Link to="/contact" className='text-white text-[25px]'>Contact</Link> */}
                            </div>
                        </div>
                    </Slider>
                </div>
            )}
        </div>
    )
}

// export default Wrapper