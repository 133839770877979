// import React from 'react'
import { MdOutlineArrowOutward } from "react-icons/md";

import Img1 from "../asset/interior-design-modern-apartment-with-cozy-furniture-wabisabi-style-3d-render.jpg"
import Img2 from "../asset/stylish-living-room-interior-with-design-furnitre-elegant-accessories-3d-render.jpg"
import Slider from "./Slider";

import Simg1 from "../asset/simg1.png"
import Simg2 from "../asset/simg2.jpg"
import Simg3 from "../asset/simg3.jpg"
import Simg4 from "../asset/simg4.jpeg"



// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
// import { Mousewheel, Pagination } from 'swiper/modules';
// import Wrapper5Mobile from "./Wrapper5Mobile";



const Wrapper5 = () => {
    return (
        <div className="mt-20">
            <div className="max-w-[1140px] mx-auto">

                <div className="flex flex-col items-center gap-6">
                    <h6 className="text-[17px] font-semibold">WORKING STYLE</h6>
                    <h3 className="text-[30px] font-semibold ml-7 lg:ml-0">Your Dream Home in 4 Steps!</h3>
                    <div className="flex shadow-md bg-white  gap-8 p-8 flex-col lg:flex-row">
                        <div className="flex flex-col items-center justify-center gap-5">
                            <img src={Simg1} alt="" className='w-[130px] h-[130px] rounded-full' />
                            <h4 className="font-bold text-[18px] w-[100px] text-center">Get Free Consultation</h4>

                            <span className="w-[260px] flex justify-center items-center leading-8 text-center">Talk to our design expert get free quote for your dream home & book JdeSign.</span>
                            <div className="text-[120px] font-bold text-[#181818] opacity-[.07] transition-all ease-in-out duration-[600ms] mt-[-140px] zoom-in">01</div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-5">
                            <img src={Simg2} alt="" className='w-[130px] h-[130px] rounded-full' />

                            <h4 className="font-bold text-[18px] w-[100px] text-center">3D & 2D Design</h4>
                            <span className="w-[260px] flex justify-center items-center leading-8 text-center">Get starts with 3D Design after finalize the design. Will start 2D for detail drawing.</span>
                            <div className="text-[120px] font-bold text-[#181818] opacity-[.07] transition-all ease-in-out duration-[600ms] mt-[-140px] zoom-in">02</div>

                        </div>
                        <div className="flex flex-col items-center justify-center gap-5">
                            <img src={Simg3} alt="" className='w-[130px] h-[130px] rounded-full' />

                            <h4 className="font-bold text-[18px] w-[120px] text-center">Production & Installation</h4>
                            <span className="w-[260px] flex justify-center items-center leading-8 text-center">We delivering a diverse scale of projects including restorations project</span>
                            <div className="text-[120px] font-bold text-[#181818] opacity-[.07] transition-all ease-in-out duration-[600ms] mt-[-140px] zoom-in">03</div>

                        </div>
                        <div className="flex flex-col items-center justify-center gap-5">
                            <img src={Simg4} alt="" className='w-[130px] h-[130px] rounded-full' />

                            <h4 className="font-bold text-[18px] w-[100px] text-center">Key Handover</h4>
                            <span className="w-[260px] flex justify-center items-center leading-8 text-center">Your dream home interior is now ready to move in. You dream it ! We gave it.</span>
                            <div className="text-[120px] font-bold text-[#181818] opacity-[.07] transition-all ease-in-out duration-[600ms] mt-[-140px] zoom-in">04</div>

                        </div>
                    </div>

                </div>
            </div></div>
    )
}

export default Wrapper5




