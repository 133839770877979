import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';



// import './styles.css';
import { MdBed } from "react-icons/md";
import { SiApachecouchdb } from "react-icons/si";
import { GiSteelDoor } from "react-icons/gi";
import { FreeMode, Pagination } from 'swiper/modules';
import { FaEarthAfrica } from "react-icons/fa6";
import { FaArtstation } from "react-icons/fa";
import { FaDigitalOcean } from "react-icons/fa";
import { FaCreativeCommonsSa } from "react-icons/fa";
import Image1 from "../asset/img-40.jpg"
import Image2 from "../asset/img-39.jpg"
import Image3 from "../asset/img-12.jpg"
import Image4 from "../asset/img-11.jpg"
import Image5 from "../asset/img-14.jpg"
import Image6 from "../asset/img-15.jpg"
import Image7 from "../asset/img-16.jpg"
import Image8 from "../asset/img-17.jpg"
import WrapperMobile from "./Wrapper4Mobile"
import Simg5 from "../asset/col-bgimage-1.jpg"
import { FaStar } from "react-icons/fa6";
import Kitchen from "../asset/kitchen (3).png"
import Bedroom from "../asset/bedroom.png"
import Door from "../asset/door (1).png"
import Living from "../asset/living-room.png"
import Foyer from "../asset/curtain (1).png"
import Celing from "../asset/dining-table.png"
import Renovation from "../asset/people (1).png"


// import CountertopsIcon from '@mui/icons-material/Countertops';

const Wrapper4 = () => {

    return (

        <div>
            {/* <WrapperMobile className="block md:hidden" /> */}
            <div className="mt-28 ">
                <div className="ml-5  py-20">
                    <div className='max-w-[1200px] mx-auto'>
                        <h2 className='text-[25px] font-semibold text-[orange]'>SERVICES WE DO</h2>
                        <h1 className='text-[40px] font-semibold  mt-3'>Our Best Services Interior</h1>

                        <div className='ourbest mt-10 '>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white   '>

                                {/* <CountertopsIcon /> */}
                                <img src={Kitchen} alt="" className='w-[25%] ' />

                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>Modular Kitchen</h3>
                                <span className='w-[250px] text-gray-600'>Highly efficient & organized, making it easy to use all appliances and storage.</span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                <img src={Bedroom} alt="" className='w-[25%]' />

                                {/* <MdBed className='text-[50px] text-[orange]' /> */}
                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    Bedroom Design
                                </h3>
                                <span className='w-[240px] text-gray-600'>A space that is comfortable, relaxing, & reflective of your personal style.</span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                {/* <GiSteelDoor className='text-[50px] text-[orange]' /> */}
                                {/* <GiSteelDoor className='text-[3rem] ml-[-8px] text-[orange]' /> */}
                                <img src={Door} alt="" className='w-[25%]' />

                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    Pooja Unit
                                </h3>
                                <span className='w-[220px] text-gray-600'>Should have adequate storage for puja items, books, and other accessories. </span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                {/* <i className="fas fa-living-room"></i> */}
                                <img src={Living} alt="" className='w-[25%]' />
                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    Living Design
                                </h3>
                                <span className='w-[220px] text-gray-600'>A place for relaxation, socializing, comfortable & inviting atmosphere for family.</span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                <img src={Foyer} alt="" className='w-[25%]' />
                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    Foyer Design
                                </h3>
                                <span className=' w-[220px] text-gray-600'>A place to make a statement and create a welcoming atmosphere. </span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                <img src={Celing} alt="" className='w-[25%]' />

                                {/* <SiApachecouchdb className='text-[50px] text-[orange]' /> */}
                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    False Celing
                                </h3>
                                <span className='w-[220px] text-gray-600'>An excellent opportunity to add lighting to a stylish room and functional touch.</span>
                            </div>
                            <div className='shadow-md py-5 pr-4 pl-10 flex flex-col gap-3 bg-white'>
                                <img src={Renovation} alt="" className='w-[25%]' />

                                <h3 className='text-[21px] font-semibold w-[230px] hover:text-[orange]'>
                                    Renovation
                                </h3>
                                <span className='w-[230px] text-gray-600'>We are master of renovation & innovation of existing any kind of rooms </span>
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row'>
                            <div className='flex gap-5 lg:mt-40 mt-10 flex-col lg:flex-row'>
                                <div className='flex gap-1'>

                                    <FaStar className='text-orange-400' />
                                    <FaStar className='text-orange-400' />
                                    <FaStar className='text-orange-400' />
                                    <FaStar className='text-orange-400' />
                                    <FaStar className='text-orange-400' />
                                </div>
                                <p className='text-gray-600 lg:w-[430px] w-[350px] text-[15px] tracking-wider'><span className='text-orange-500 underline font-semibold'>90% Customer Satisfaction</span>  based on 150+ reviews and 20,000 Objective Resource</p>
                            </div>
                            <div className='flex flex-col lg:flex-none mt-60 lg:mt-0'>
                                <img src={Simg5} alt="" className='lg:w-[37rem] w-[20rem] lg:h-[450px] z-[-1] mt-[-230px] lg:ml-[4rem]' />
                                <div className='bg-orange-500 text-white lg:mt-[-80px] mt-[-70px] lg:ml-[7rem] ml-[2rem] lg:w-[500px] w-[300px] rounded-3xl p-2'>
                                    <span className='lg:text-[17px] text-[10px] font-semibold ml-16'>OUR Featured Services Interior Design</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    < WrapperMobile className="lg:hidden block " />
                    <div className='mt-32 lg:block hidden'>
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={60}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={Image1} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image2} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image3} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image4} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image5} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image6} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image7} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image8} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Image4} className='w-[300px] h-[400px]' />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Wrapper4;
