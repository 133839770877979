import React from "react";

const CardItem = ({ name, desc, style }) => (
    <div
        style={{
            width: 300,
            height: 408,
            background: "#000",
            color: "#fff",
            borderRadius: 5,
            textAlign: "center",
            ...style,
        }}
    >
        <h3>{name}</h3>
        <p>{desc}</p>
    </div>
);

export default CardItem;
