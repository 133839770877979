// Preloader.js
import React from "react";

const Preloader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75 z-50">
      <div className="border-4 border-opacity-25 border-gray-400 rounded-full w-12 h-12 animate-spin"></div>
    </div>
  );
};

export default Preloader;
